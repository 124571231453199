<template>
  <section class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10 p-0">
        <p class="h2 text-secondary">
          <i
            style="font-size: 22px; margin-left: 2%"
            :class="$route.meta.iconClass"
          ></i>
          <span
            style="font-size: 18px; margin-left: 0.5%"
            class="font-weight-light"
            >Objectif stratégique :
          </span>
          <span style="font-size: 22px">{{ objectif.libelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <h4 class="card-title mt-4 mb-1" style="margin-left: 2%">
          Liste feuilles de route :
        </h4>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(avancement)="data">
            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :style="{ backgroundColor: variant(data.value) }"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              :to="{
                name: 'show-fdr',
                params: {
                  id: data.item.id,
                  slug: data.item.slug,
                },
              }"
            >
              {{ data.value }}
            </b-link>
          </template>
          <template v-slot:cell(type)="data">
            <div>
              {{ data.value }}
            </div>
          </template>

          <template v-slot:cell(status)="data">
            <label>{{ data.value }}</label>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  props: {
    objectif: Object,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      loading: true,
      tableItems: [],

      tableFields: [
        {
          key: "name",
          label: "FDR",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
          sortable: false,
        },
        {
          key: "avancement",
          label: "Avancement",
          sortable: false,
          thStyle: { textAlign: "center !important" },
        },
      ],
    };
  },

  methods: {
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
  },
  created() {
    this.$store
      .dispatch("objectif/fetchFdrsObjectif", this.objectif.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("objectif", ["FDRS"]), //get resposables
    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    FDRS() {
      this.tableItems = this.FDRS.feuilleRoutes;
    },
  },
};
</script>

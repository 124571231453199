<template>
  <div id="app">
    <div class="row align-items-center justify-items-center">
      <div
        style="
          border-bottom: 1px solid black;
          margin-left: 20px !important;
          margin-right: 20px !important;
        "
        class="w-100 d-flex align-items-center justify-content-between p-0 m-0"
      >
        <h4 class="card-title my-auto" style="font-size: 1.13rem">
          Liste des objectifs
        </h4>
        <div
          class="row justify-content-end pr-3"
          style="width: calc(100% - 152px)"
        >
          <div class="pr-2 p-0 mb-2 col-md-3">
            <!-- {{ organisationType }} -->
            <v-select
              :options="organisationType"
              placeholder="Organisation"
              v-model="searchQuery.organisation"
            />
          </div>
          <div class="pr-2 p-0 mb-2 col-md-3">
            <v-select
              placeholder="Responsable"
              :options="responsables"
              v-model="responsable"
              :reduce="(responsable) => responsable.value"
              label="text"
            />
          </div>
          <div class="pr-2 p-0 mb-2 col-md-3">
            <b-form-input
              type="text"
              v-model="searchQuery.objectif"
              placeholder="Objectif"
            ></b-form-input>
          </div>
          <div class="pr-2 p-0 mb-2 col-md-3">
            <b-form-input
              v-model="searchQuery.vision"
              type="text"
              placeholder="Vision"
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <GoalTable
      class="mt-3"
      :fields="tableFields"
      :items="filteredObjectifs"
      @showFdr="showFdrFunction"
      @objectifClicked="onObjectif"
      @visionClicked="onTask"
      :loading="loadingGoals"
    />

    <div v-if="showFdr" to="modals-xyz-548">
      <Modal @close="showFdr = !showFdr">
        <FdrList :objectif="selectedFDR" />
      </Modal>
    </div>
    <!-- v-if="showDescLst"  -->
    <div v-if="selectedObjective.length > 0" to="modals-xyz-548">
      <Modal @close="selectedObjective = []">
        <DescriptionList :tableItems="selectedObjective" />
      </Modal>
    </div>
    <div v-if="selectedVision.length > 0" to="modals-xyz-548">
      <Modal @close="selectedVision = []">
        <VisionsList :tableItems="selectedVision" />
      </Modal>
    </div>
  </div>
</template>

<script>
import GoalTable from "./components/GoalTable.vue";
import FdrList from "./components/FdrList";
import DescriptionList from "./components/DescriptionList.vue";
import VisionsList from "./components/VisionsList.vue";

import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";
// import DropDownBox from '@/components/custom/DropDownBox.vue';

export default {
  name: "visionScreen",
  data() {
    return {
      responsable: null,
      showFdr: false,
      searchQuery: {
        vision: "",
        organisation: "",
        responsable: "",
        objectif: "",
      },
      tableFields: [
        { key: "objectif", label: "Objectif stratégique", sortable: true },
        // { key: "desription", label: "Description", sortable: false },
        { key: "vision", label: "Vision", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "organisationResp",
          label: "Entité/Organisation",
          sortable: true,
        },
        {
          key: "feuilleRoutes",
          label: "Alignement",
          sortable: true,
          thStyle: { textAlign: "center !important" },
        },
        // { key: "kpis", label: "Kpis", sortable: false,thStyle: {textAlign:"center !important"},  },
      ],
      fdrListData: [],
      showDescLst: false,
      loadingGoals: true,
      selectedObjective: [],
      selectedVision: [],
      selectedFDR: null,
    };
  },
  components: {
    GoalTable,
    FdrList,
    Modal,
    DescriptionList,
    // DropDownBox,
    VisionsList,
  },
  methods: {
    newTask: function () {},
    showFdrFunction: async function (objectif) {
      this.showFdr = !this.showFdr;
      this.selectedFDR = objectif;
    },
    onObjectif: function (item) {
      this.selectedObjective.push({
        ...item,
        label: item.libelle,
        description: item.desription,
      });
    },
    onTask: function (item) {
      this.selectedVision.push(
        ...this.visions.filter((vi) => vi.id == item.id)
      );
    },
    onFDR: async function (item) {
      this.selectedFDR.push(...this.fdrs.filter((fi) => fi.id == item.id));
      this.showFdr = true;
    },
  },
  computed: {
    ...mapGetters("objectif", ["OBJECTIFS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("vision", {
      visions: "VISIONS",
    }),
    ...mapGetters("fdr", {
      fdrs: "FDRS",
    }),
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    organisationType: function () {
      return this.ORGANISATIONS.filter((orga) => orga.entite).map(
        (organisation) =>
          `${organisation.entite.libelle}/${organisation.libelle}`
      );
    },
    // SEARCH FUNCTION
    filteredObjectifs: function () {
      let tmpDt = this.OBJECTIFS.map((objectif) => ({
        ...objectif,
        avancement: (objectif.avancement || 0).toFixed(2),
      })).filter((objectif) => {
        return (
          (this.searchQuery.organisation
            ? `${objectif.organisationResp.entite.libelle.toUpperCase()}/${objectif.organisationResp.libelle.toUpperCase()}`.includes(
                this.searchQuery.organisation.toUpperCase()
              )
            : true) &&
          objectif.libelle
            .toLowerCase()
            .indexOf(this.searchQuery.objectif.toLowerCase()) > -1 &&
          (objectif.vision
            ? objectif.vision.libelle
                .toLowerCase()
                .indexOf(this.searchQuery.vision.toLowerCase()) > -1
            : true) &&
          (this.responsable
            ? objectif.responsable.id === parseInt(this.responsable)
            : true)
        );
      });

      if (tmpDt.length > 0) {
        return tmpDt;
      } else {
        return [];
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("objectif/fetchAllObjectifs").then(() => {
      this.loadingGoals = false;
    });
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("vision/fetchAllVisions");
    this.$store.dispatch("fdr/fetchAllFdrs");
  },

  watch: {
    OBJECTIFS: function () {
      window.console.table(this.OBJECTIFS);
    },
  },
};
</script>

<style>
.styleSelect .vs__search::placeholder,
.styleSelect .vs__dropdown-toggle,
.styleSelect .vs__dropdown-menu {
  background: #ffffff;
  border: none;
  color: #9b9b9b;
  text-transform: lowercase;
  font-variant: small-caps;
}

.styleSelect .vs__dropdown-toggle {
  height: 44px;
  width: 220px;
  border: 1px rgb(212, 212, 212) solid;
}

.styleSelect .vs__clear,
.styleSelect .vs__open-indicator {
  fill: #000000;
}
</style>

<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(organisationResp)="data">
                <span>{{
                  `${data.value.entite.libelle} / ${data.value.libelle}`
                }}</span>
              </template>

              <template v-slot:cell(kpis)="data">
                <div style="text-align: center">
                  <span class="">{{
                    data.value.length ? data.value[0].name : "-"
                  }}</span>
                </div>
              </template>

              <template v-slot:cell(desription)="data">
                <div class="text-truncate" style="max-width: 100px">
                  <span>{{ data.value }}</span>
                </div>
              </template>

              <template v-slot:cell(feuilleRoutes)="data">
                <div style="text-align: center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    @click="$emit('showFdr', data.item)"
                    >{{ data.value.length }}</b-badge
                  >
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ data.value.length }}</b-badge
                  >
                </div>
              </template>

              <template v-slot:cell(goal)="data">
                <div class="d-flex justify-content-center">
                  <span class="text-center p-0">{{ data.value }}</span>
                </div>
              </template>

              <template v-slot:cell(vision)="data">
                <!-- <div>
                  <span
                    @click="$emit('visionClicked', data.item.vision)"
                    style="cursor: pointer; color: blue; text-decoration: underline"
                    >{{ data.value.libelle }}</span
                  >
                </div> -->
                <b-link
                  :to="{
                    name: 'show-vision',
                    params: {
                      id: data.value.id,
                      slug: data.value.slug || 'abcde',
                    },
                  }"
                >
                  {{ data.value.libelle }}
                </b-link>
              </template>

              <template v-slot:cell(responsable)="data">
                <TableResponsable :responsable="data.value" />
              </template>
              <template v-slot:cell(objectif)="data">
                <b-link
                  :to="{
                    name: 'show-objectif',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || 'abcde',
                    },
                  }"
                >
                  {{ data.item.libelle }}
                </b-link>
              </template>
              <template v-slot:cell(avancement)="data">
                <b-progress
                  max="100"
                  show-progress
                  style="background-color: #cacccd !important"
                  height="15px"
                >
                  <b-progress-bar
                    :style="{ backgroundColor: variant(data.value) }"
                    :value="data.value"
                    :label="`${data.value}%`"
                  ></b-progress-bar>
                </b-progress>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableResponsable },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "objectif", label: "Objectif stratégique", sortable: true },
        // { key: "desription", label: "Description", sortable: false },
        { key: "vision", label: "Vision", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "organisationResp",
          label: "Entité/Organisation",
          sortable: true,
        },
        {
          key: "avancement",
          label: "Avancement",
          sortable: true,
        },
        {
          key: "feuilleRoutes",
          label: "Alignement",
          sortable: true,
          thStyle: { textAlign: "center !important" },
        },
        // { key: "kpis", label: "Kpis", sortable: false,thStyle: {textAlign:"center !important"},  },
      ],
    };
  },
  methods: {
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  props: {
    // fields: Array,
    items: Array,
    loading: Boolean,
  },
};
</script>

<template>
  <section class="tables">
    <!-- <div class="row">
      <div class="col-12"><p class="mb-3 h2">Objectif stratégique</p></div>

      <div class="col-12"><p class="mb-3 h3">Liste feuilles de route</p></div>
    </div>
    <hr /> -->
    <div class="row">
      <div class="col-12 p-0">
        <p class="h2 text-secondary">
          <i
            style="font-size: 22px; margin-left: 2%"
            class="mdi mdi-lightbulb-on-outline"
          ></i>
          <span
            style="margin-left: 0.5%; font-size: 18px"
            class="font-weight-light"
            >Nom :
          </span>
          <span style="font-size: 22px">{{ tableItems[0].libelle }}</span>
        </p>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          show-empty
          bordred
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(responsable)="data">
            <TableResponsable :responsable="data.value" />
          </template>
          <!-- <template v-slot:cell(progress)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(responsable)="data">
            <div>
              <b-avatar
                v-b-tooltip.hover="data.value.firstname+' ' +data.value.lastname"
                variant="primary"
                size="2rem"
                :src="data.value.img"
                class="mr-1"
              ></b-avatar>
              {{data.value.firstname[0].toUpperCase()+' ' +data.value.lastname[0].toUpperCase() }}
            </div>
          </template>

          <template v-slot:cell(actions)="data">
            <div class="d-flex">
              <b-button
                v-if="data.value.includes('show')"
                @click="$emit('showItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-eye"></i
              ></b-button>
              <b-button
                v-if="data.value.includes('edit')"
                @click="$emit('editItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-pencil-outline"></i
              ></b-button>
              <b-button
                v-if="data.value.includes('delete')"
                @click="$emit('deleteItem', data.item)"
                variant="light"
                pill
                size="sm"
                style="width: 40px; height: 40px"
                class="d-flex align-items-center justify-content-center"
                ><i class="mdi mdi-delete"></i
              ></b-button>
            </div>
          </template> -->
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableResponsable },
  props: {
    tableItems: Array,
    loading: Boolean,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "libelle", label: "Libellé/Titre", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "startedAt", label: "Date début", sortable: true },
        { key: "endingAt", label: "Date fin", sortable: true },
      ],
      // tableItems: [
      //   {
      //     type: "FDR",
      //     Initiative: "CRM Agent",
      //     name: `Fdr 1`,
      //     nb_initiatives: 5,
      //     deadline: "R",
      //     organisation: "minus",
      //     status: "Suspendu",
      //     responsable: {
      //       name: "S.E",
      //       full_name: "Steve Ewore",
      //       img: "https://source.unsplash.com/random/150x150",
      //     },
      //     progress: 65,
      //   },
      //   {
      //     type: "FDR",
      //     Initiative: "CRM Agent",
      //     name: `Fdr 2`,
      //     nb_initiatives: 5,
      //     deadline: "R",
      //     status: "Validation fin",
      //     organisation: "minus",
      //     responsable: {
      //       name: "S.E",
      //       full_name: "Steve Ewore",
      //       img: "https://source.unsplash.com/random/150x150",
      //     },
      //     progress: 20,
      //   },
      //   {
      //     type: "FDR",
      //     Initiative: "CRM Agent",
      //     name: `Fdr 3`,
      //     nb_initiatives: 5,
      //     deadline: "R",
      //     status: "Proposé",
      //     organisation: "minus",
      //     responsable: {
      //       name: "S.E",
      //       full_name: "Steve Ewore",
      //       img: "https://source.unsplash.com/random/150x150",
      //     },
      //     progress: 90,
      //   },
      // ],
    };
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    tableItems() {
      window.console.table(this.tableItems);
    },
  },
};
</script>
